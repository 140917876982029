import { Transition } from "@headlessui/react";
import { cx } from "class-variance-authority";
import * as React from "react";
import scrollIntoView from "smooth-scroll-into-view-if-needed";
import { SectionElement } from "./type";

interface Props {
  sections: SectionElement[];
}

function getTop(element: HTMLDivElement) {
  return element.getBoundingClientRect().top;
}

export const NavigationSticky = React.forwardRef<HTMLDivElement, Props>(
  function NavigationSticky({ sections }, stickyRef) {
    const [activeIndex, setActiveIndex] = React.useState<number | null>(null);
    const [percent, setPercent] = React.useState(0);
    const [isVisible, setIsVisible] = React.useState(false);

    const handleScroll = React.useCallback(() => {
      let activeIdx = activeIndex;
      for (const [index, section] of sections.entries()) {
        const top = getTop(section.ref) - 100;

        if (top <= 0) {
          setActiveIndex(index);
          activeIdx = index;
          continue;
        }
      }

      const firstElement = sections[0].ref;
      const firstElementOffsetTop = firstElement.offsetTop + 100 - 20 - 120;

      const lastElement = sections[sections.length - 1].ref;
      const lastElementHeight = lastElement.clientHeight;
      const lastElementOffsetTop = lastElement.offsetTop;

      if (
        getTop(firstElement) <= 120 &&
        getTop(lastElement) + lastElementHeight > 0
      ) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }

      if (activeIdx === null || activeIdx + 1 > sections.length - 1) {
        return;
      }

      const elementA = sections[activeIdx].ref.offsetTop;
      const elementB = sections[activeIdx + 1].ref.offsetTop;

      const totalDistance = elementB - elementA;
      const scrolledDistance = window.scrollY - elementA;

      const percent = Math.min(
        100,
        Math.max(0, (scrolledDistance / totalDistance) * 100)
      );
      setPercent(percent);
    }, [activeIndex, sections]);

    const scrollToSection = React.useCallback((sectionRef: HTMLDivElement) => {
      scrollIntoView(sectionRef, {
        block: "start",
        inline: "start",
        behavior: "smooth",
      });
    }, []);

    React.useEffect(() => {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, [handleScroll]);

    return (
      <Transition
        show={isVisible}
        enter="transition-all duration-150"
        enterFrom="opacity-0 -translate-y-full"
        enterTo="opacity-100 translate-y-0"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 -translate-y-full"
        className="w-full shadow-lg h-16 from-tablet:h-20 py-3 from-tablet:py-4 bg-secondary fixed top-0 left-0 z-50"
      >
        <div ref={stickyRef} className="w-full h-full relative">
          <div className="container relative">
            <div className="flex justify-between items-center flex-nowrap bg-secondary relative z-10">
              {sections.map((section, index) => {
                const isActive = index === activeIndex;
                const fullWidth = activeIndex !== null && index < activeIndex;
                const isLast = sections.length - 1 === index;
                return (
                  <React.Fragment key={index}>
                    <div className="flex flex-col items-center relative">
                      <span
                        className={cx(
                          "text-sm drop-shadow cursor-pointer text-primary whitespace-nowrap",
                          "  block",
                          isActive
                            ? "only-mobile:opacity-1"
                            : "only-mobile:opacity-0 only-mobile:!w-0"
                        )}
                        onClick={() => {
                          if (!isActive) {
                            scrollToSection(section.ref);
                          }
                        }}
                      >
                        {section.title}
                      </span>
                      <div
                        className={cx(
                          "w-4 h-4 from-tablet:w-6 from-tablet:h-6 bg-action drop-shadow from-tablet:transition-opacity shrink-0",
                          isActive ? "opacity-1" : "opacity-0"
                        )}
                      />
                      <div
                        className={cx(
                          "absolute bottom-2 from-tablet:bottom-3 left-0 bg-action h-1",
                          (fullWidth || (isActive && percent > 0)) && "w-full",
                          percent === 0 && isActive && "w-1/2",
                          fullWidth && "only-mobile:w-full",
                          isActive && !isLast && "only-mobile:!w-1/2"
                        )}
                      />
                    </div>
                    <div className="w-full h-1 relative last:hidden mt-auto pb-3 from-tablet:pb-4">
                      <div
                        className={cx(
                          "bg-action h-1",
                          "absolute top-0 left-0",
                          fullWidth ? "!w-full" : "w-0 only-mobile:hidden"
                        )}
                        style={{ width: isActive ? `${percent}%` : 0 }}
                      />
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
          <div
            className={cx(
              "absolute h-1 bottom-3 from-tablet:bottom-4 left-0",
              activeIndex === sections.length - 1 ? "w-full" : "w-1/2"
            )}
          >
            <div className="bg-action h-full" />
          </div>
        </div>
      </Transition>
    );
  }
);
