import * as React from "react";
import { NavigationSticky } from "./NavigationSticky";
import { useNavigationSticky } from "./NavigationStickyProvider";

export function NavigationStickyWrapper() {
  const [hasSections, setHasSections] = React.useState(false);
  const { sectionsObject, setStickyHeight } = useNavigationSticky();

  React.useEffect(() => {
    if (sectionsObject.length > 0 && !hasSections) {
      setHasSections(true);
    }
  }, [hasSections, sectionsObject, setStickyHeight]);

  if (!hasSections) {
    return null;
  }

  return <NavigationSticky sections={sectionsObject} />;
}
