export interface ContentComponent<Type> {
  data: Type;
  name: string;
}

export type NormalizedContent = Array<ContentComponent<unknown>>;

export function isContentComponentType<Type>(
  element: ContentComponent<any>,
  name: string
): element is ContentComponent<Type> {
  return isType<ContentComponent<Type>>(element, name);
}

function isType<Type>(element: unknown, name: string): element is Type {
  if (typeof element === "object" && element !== null && "name" in element) {
    return element.name === name;
  }
  return false;
}
