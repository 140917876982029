import {
  AnimationTextData,
  BannerAnimationData,
  BannerData,
  CarouselData,
  CodeBlocksData,
  ColumnSectionData,
  ContactData,
  ContactFormData,
  FaqData,
  LogoCarouselData,
  NavigationStickyProvider,
  NavigationStickyWrapper,
  NewsletterData,
  SimpleHtmlBlockData,
  TabsComponentData,
} from "@bs/components";
import dynamic from "next/dynamic";
import * as React from "react";
import { ContentComponent } from "./ContentComponent";
import { isEnabled } from "./helpers/isEnabled";
import { toNumber } from "./helpers/toNumber";
import { NormalizedContent, isContentComponentType } from "./types";

const AnimationText = dynamic(async () => {
  return (await import("@bs/components")).AnimationText;
});

const LogoCarousel = dynamic(async () => {
  return (await import("@bs/components")).LogoCarousel;
});

const BannerAnimation = dynamic(async () => {
  return (await import("@bs/components")).BannerAnimation;
});

const ColumnSection = dynamic(async () => {
  return (await import("@bs/components")).ColumnSection;
});

const TabsComponent = dynamic(async () => {
  return (await import("@bs/components")).TabsComponent;
});

const Carousel = dynamic(async () => {
  return (await import("@bs/components")).Carousel;
});

const Banner = dynamic(async () => {
  return (await import("@bs/components")).Banner;
});

const ContactForm = dynamic(async () => {
  return (await import("@bs/components")).ContactForm;
});

const Contact = dynamic(async () => {
  return (await import("@bs/components")).Contact;
});

const CodeBlocks = dynamic(async () => {
  return (await import("@bs/components")).CodeBlocks;
});

const SimpleHtmlBlock = dynamic(async () => {
  return (await import("@bs/components")).SimpleHtmlBlock;
});

const FAQ = dynamic(async () => {
  return (await import("@bs/components")).FAQ;
});

const Newsletter = dynamic(async () => {
  return (await import("@bs/components")).Newsletter;
});

interface ContentsProps {
  content: NormalizedContent;
}

export const Contents = React.memo(function Contents({
  content,
}: ContentsProps) {
  const firstSectionWithTitle = React.useMemo(() => {
    for (const [index, item] of content.entries()) {
      if (
        typeof item.data === "object" &&
        item.data !== null &&
        "sectionTitle" in item.data &&
        item.data.sectionTitle
      ) {
        return `contentComponent_${index}`;
      }
    }

    return "";
  }, [content]);

  return (
    <NavigationStickyProvider>
      <NavigationStickyWrapper />
      {content.map((component, index) => {
        const componentId = `contentComponent_${index}`;
        const random = Math.floor(Math.random() * 1001);

        if (
          isContentComponentType<AnimationTextData>(component, "animation-text")
        ) {
          const { isStatic, sectionTitle, speed, ...restData } = component.data;

          return (
            <ContentComponent
              componentId={componentId}
              key={`${componentId}_${random}`}
              sectionTitle={sectionTitle}
              firstSectionWithTitle={firstSectionWithTitle}
            >
              <AnimationText
                {...restData}
                isStatic={!!isEnabled(isStatic)}
                animationSpeed={toNumber(speed)}
              />
            </ContentComponent>
          );
        }

        if (
          isContentComponentType<LogoCarouselData>(component, "logo-carousel")
        ) {
          const {
            animateLogo,
            animationLoop,
            autoPlay,
            animationSpeed,
            sectionTitle,
            ...restData
          } = component.data;
          return (
            <ContentComponent
              componentId={componentId}
              key={`${componentId}_${random}`}
              sectionTitle={sectionTitle}
              firstSectionWithTitle={firstSectionWithTitle}
            >
              <LogoCarousel
                {...restData}
                animateLogo={isEnabled(animateLogo)}
                animationLoop={isEnabled(animationLoop)}
                autoPlay={isEnabled(autoPlay)}
                animationSpeed={toNumber(animationSpeed)}
              />
            </ContentComponent>
          );
        }

        if (
          isContentComponentType<BannerAnimationData>(
            component,
            "banner-animation"
          )
        ) {
          const {
            items,
            sectionTitle,
            defaultOpenedOnMobile,
            animationDelay,
            animationSpeed,
          } = component.data;

          return (
            <ContentComponent
              sectionTitle={sectionTitle}
              firstSectionWithTitle={firstSectionWithTitle}
              componentId={componentId}
              key={`${componentId}_${random}`}
            >
              <BannerAnimation
                items={items}
                defaultOpenedOnMobile={toNumber(defaultOpenedOnMobile)}
                animationDelay={toNumber(animationDelay)}
                animationSpeed={toNumber(animationSpeed)}
              />
            </ContentComponent>
          );
        }

        if (
          isContentComponentType<ColumnSectionData>(component, "column-section")
        ) {
          const { items, animationSpeed, sectionTitle } = component.data;
          return (
            <ContentComponent
              sectionTitle={sectionTitle}
              firstSectionWithTitle={firstSectionWithTitle}
              componentId={componentId}
              key={`${componentId}_${random}`}
            >
              <ColumnSection
                items={items}
                animationSpeed={toNumber(animationSpeed)}
              />
            </ContentComponent>
          );
        }

        if (
          isContentComponentType<TabsComponentData>(component, "tabs-component")
        ) {
          const {
            items,
            initialTab,
            sectionTitle,
            extraSpacing,
            animationSpeed,
            title,
          } = component.data;
          return (
            <ContentComponent
              sectionTitle={sectionTitle}
              firstSectionWithTitle={firstSectionWithTitle}
              componentId={componentId}
              key={`${componentId}_${random}`}
            >
              <TabsComponent
                items={items}
                initialTab={toNumber(initialTab)}
                extraSpacing={isEnabled(extraSpacing)}
                animationSpeed={toNumber(animationSpeed)}
                title={title && typeof title === "string" ? title : undefined}
              />
            </ContentComponent>
          );
        }

        if (isContentComponentType<CarouselData>(component, "carousel")) {
          const { items, initialIndex, sectionTitle, isStatic, title } =
            component.data;
          return (
            <ContentComponent
              sectionTitle={sectionTitle}
              firstSectionWithTitle={firstSectionWithTitle}
              componentId={componentId}
              key={`${componentId}_${random}`}
            >
              <Carousel
                items={items}
                initialIndex={toNumber(initialIndex)}
                isStatic={isEnabled(isStatic)}
                title={title}
              />
            </ContentComponent>
          );
        }

        if (isContentComponentType<BannerData>(component, "banner")) {
          const { sectionTitle, ...data } = component.data;
          return (
            <ContentComponent
              sectionTitle={sectionTitle}
              firstSectionWithTitle={firstSectionWithTitle}
              componentId={componentId}
              key={`${componentId}_${random}`}
            >
              <Banner {...data} />
            </ContentComponent>
          );
        }

        if (
          isContentComponentType<ContactFormData>(component, "contact-form")
        ) {
          const { animationSpeed, withoutMargin, sectionTitle, ...restProps } =
            component.data;
          return (
            <ContentComponent
              componentId={componentId}
              key={`${componentId}_${random}`}
              sectionTitle={sectionTitle}
              firstSectionWithTitle={firstSectionWithTitle}
            >
              <ContactForm
                {...restProps}
                withoutMargin={isEnabled(withoutMargin)}
                animationSpeed={toNumber(animationSpeed)}
              />
            </ContentComponent>
          );
        }

        if (isContentComponentType<ContactData>(component, "contact")) {
          const { items, animationSpeed } = component.data;
          return (
            <ContentComponent
              componentId={componentId}
              key={`${componentId}_${random}`}
            >
              <Contact
                items={items}
                animationSpeed={toNumber(animationSpeed)}
              />
            </ContentComponent>
          );
        }

        if (isContentComponentType<CodeBlocksData>(component, "code-blocks")) {
          const { showLineNumbers, startingLineNumber, ...restProps } =
            component.data;
          return (
            <ContentComponent
              componentId={componentId}
              key={`${componentId}_${random}`}
            >
              <CodeBlocks
                {...restProps}
                showLineNumbers={isEnabled(showLineNumbers)}
                startingLineNumber={toNumber(startingLineNumber)}
              />
            </ContentComponent>
          );
        }

        if (
          isContentComponentType<SimpleHtmlBlockData>(
            component,
            "simple-html-block"
          )
        ) {
          return (
            <React.Fragment key={`${componentId}_${random}`}>
              <SimpleHtmlBlock componentId={componentId} {...component.data} />
            </React.Fragment>
          );
        }

        if (isContentComponentType<FaqData>(component, "faq")) {
          const { sectionTitle, defaultOpened, ...restProps } = component.data;
          return (
            <ContentComponent
              componentId={componentId}
              key={`${componentId}_${random}`}
              sectionTitle={sectionTitle}
              firstSectionWithTitle={firstSectionWithTitle}
            >
              <FAQ {...restProps} defaultOpened={toNumber(defaultOpened)} />
            </ContentComponent>
          );
        }

        if (isContentComponentType<NewsletterData>(component, "newsletter")) {
          const { sectionTitle, ...restProps } = component.data;
          return (
            <ContentComponent
              componentId={componentId}
              key={`${componentId}_${random}`}
              sectionTitle={sectionTitle}
              firstSectionWithTitle={firstSectionWithTitle}
            >
              <Newsletter {...restProps} />
            </ContentComponent>
          );
        }
      })}
    </NavigationStickyProvider>
  );
});
