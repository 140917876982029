import * as React from "react";
import { SectionElement } from "./type";

type NavigationStickyContext = {
  sectionsObject: SectionElement[];
  stickyHeight: number;

  setSection: (section: SectionElement) => void;
  setSectionPermanent: (section: SectionElement) => void;
  setStickyHeight: (height: number) => void;
};

const Context = React.createContext<NavigationStickyContext | null>(null);

export function NavigationStickyProvider({
  children,
}: React.PropsWithChildren<unknown>) {
  const [stickyHeight, setHeight] = React.useState(80);
  const [sectionsObject, setSectionsObject] = React.useState<SectionElement[]>(
    []
  );

  const setSection = React.useCallback((section: SectionElement) => {
    setSectionsObject((prevSections) => [...prevSections, section]);
  }, []);

  const setSectionPermanent = React.useCallback((section: SectionElement) => {
    setSectionsObject([section]);
  }, []);

  const setStickyHeight = React.useCallback((height: number) => {
    setHeight(height);
  }, []);

  const value = React.useMemo(() => {
    return {
      sectionsObject,
      stickyHeight,
      setSection,
      setStickyHeight,
      setSectionPermanent,
    };
  }, [
    sectionsObject,
    stickyHeight,
    setSection,
    setStickyHeight,
    setSectionPermanent,
  ]);
  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export const useNavigationSticky = () => {
  const context = React.useContext(Context);

  if (!context) {
    throw new Error(
      "useNavigationStickyProvider: Please provide NavigationStickyProvider value."
    );
  }
  return context;
};
