import { useNavigationSticky } from "@bs/components";
import * as React from "react";

interface ContentComponentProps {
  componentId: string;
  sectionTitle?: string;
  firstSectionWithTitle?: string;
}

export function ContentComponent({
  componentId,
  children,
  sectionTitle,
  firstSectionWithTitle,
}: React.PropsWithChildren<ContentComponentProps>) {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const [isRefSet, refHasBeenSet] = React.useState(false);
  const { setSection, stickyHeight, setSectionPermanent } =
    useNavigationSticky();

  React.useEffect(() => {
    if (ref?.current && sectionTitle && !isRefSet) {
      refHasBeenSet(true);

      if (firstSectionWithTitle === componentId) {
        setSectionPermanent({
          ref: ref.current,
          title: sectionTitle,
        });

        return;
      }
      setSection({
        ref: ref.current,
        title: sectionTitle,
      });
    }
  }, [
    componentId,
    firstSectionWithTitle,
    isRefSet,
    ref,
    sectionTitle,
    setSection,
    setSectionPermanent,
  ]);

  return (
    <div
      ref={ref}
      id={componentId}
      style={{
        scrollMarginTop:
          sectionTitle && stickyHeight ? stickyHeight + 20 : undefined,
      }}
      className="w-full"
    >
      {children}
    </div>
  );
}
